import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            kursk
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Курска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Курска готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-kurska/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-kurska/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-kurska/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.kursk}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-kurska/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.kursk}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.kursk}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-kurska/"
    },
    "headline": "Проститутки Курска, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Курска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Курска готовы ко встрече в приватной обстановке.",
    "articleBody": "С кем бы вы хотели провести сегодняшнюю ночь? С шаловливой шатенкой или жгучей брюнеткой? А может с очаровательной наивной блондинкой? Среди проституток Курска вы можете подобрать себе девочку на любой вкус. Вам в этом поможет услужливый оператор, либо вы сами можете просмотреть анкеты проституток Курска. Знойные проститутки Курска - настоящие профессионалы своего дела! Вам даже не нужно будет ничего говорить - все, что касается откровенный мужских желаний, они знают на отлично. Они помогут Вам полноценно расслабиться и сексуально разрядится. Индвидуалки Курска работают круглосуточно и чтобы этой ночью сжать в руках женщину своей мечты, есть номер телефона. Курские девочки организуют ваш интим досуг так, что вы надолго еще запомните этот вечер! Хватит сидеть и мечтать о сексуальных приключениях! Закажите себе ночь любви и страсти в лице проститутки из Курска! Достаточно просто набрать номер!",
    "image": [
        "https://vipdosug.org/images/og-kursk.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
